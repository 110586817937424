<template>
  <a-row>
    <a-col :span="20" :offset="2">
      <a-card
        title="Recipient"
        size="small"
        style="text-align: left; margin-top: 10px; background-color: #FAFAFA"
        :headStyle="{textAlign: 'left'}"
      >
        <a-form
          :model="recipient"
          name="basic"
          @finish="onFinish"
          @finishFailed="onFinishFailed"
        >
          <a-form-item
            name="fullName"
            :rules="[{ required: true, message: 'Please input Full name!' }]"
          >
            <a-input v-model:value="recipient.fullName" placeholder="Full name"/>
          </a-form-item>
          <a-form-item
            name="phone"
            :rules="[{ required: true, message: 'Please input Phone!' }]"
          >
            <a-input v-model:value="recipient.phone" placeholder="Phone"/>
          </a-form-item>
          <a-form-item
            name="email"
            :rules="[{ required: true, type: 'email', message: 'Please input email!' }]"
          >
            <a-input v-model:value="recipient.email" placeholder="Email"/>
          </a-form-item>
          <a-row>
            <a-col :span="11" style="text-align: right;">
          <a-form-item
              name="birth"
              :rules="[{ required: true, message: 'Please input birthdate!' }]"
          >
          <a-date-picker
            v-model:value="recipient.birth"
            :format="dateFormat"
            @change="updateDateOfBirth"
            placeholder="Birth date"
          />
          </a-form-item>
            </a-col>
            <a-col :span="11" :offset="2" style="text-align: right;">
              <a-form-item
                  name="issuedate"
                  :rules="[{ required: true, message: 'Please input issue date of passport!' }]"
              >
                <a-date-picker
                    v-model:value="recipient.issuedate"
                    @change="updateDateOfIssue"
                    :format="dateFormat" placeholder="Passport issue"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="11">
              <a-form-item
                name="passportSerial"
                :rules="[{ required: true, message: 'Please input passport seria!' }]"
              >
                <a-input v-model:value="recipient.passportSerial" placeholder="Passport seria"/>
              </a-form-item>
            </a-col>
            <a-col :span="11" :offset="2" style="text-align: right;">
              <a-form-item
                  name="passportNumber"
                  :rules="[{ required: true, message: 'Please input passport number!' }]"
              >
                <a-input v-model:value="recipient.passportNumber" placeholder="Passport number"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-form-item
            name="authority"
            :rules="[{ required: true, message: 'Please input issue authority!' }]"
          >
            <a-input v-model:value="recipient.authority" placeholder="Passport issue authority"/>
          </a-form-item>
          <a-form-item
            name="tin"
            :rules="[{ required: true, message: 'Please input TIN!' }]"
          >
            <a-input v-model:value="recipient.tin" placeholder="TIN"/>
          </a-form-item>
            <div v-for="(address, i) in addresses" :key="i" style="padding-top: 5px;">
              <a-card size="small">
                <template #title>
                  <a-col :span="12">
                    <h4>Address</h4>
                  </a-col>
                </template>
                <template #extra >
                  <CloseOutlined @click="removeAddress(i)" v-if="addresses.length > 1" />
                </template>
                  <a-row>
                    <a-col :span='10'>
                      <h4 style="padding-bottom: 15px;">
                        Select Country
                      </h4>
                    </a-col>
                    <a-col :span="14">
                      <a-select
                      style="width: 100%;"
                        ref="select"
                        v-model:value="country"
                        :options="countries"
                      >
                      </a-select>
                    </a-col>
                  </a-row>
                  <a-row style="padding-bottom: 15px;">
                    <a-auto-complete
                      v-model:value="address.cityName"
                      :options="address.citySug"
                      @search="getCity(i)"
                      style="width: 100%"
                      @select="onSelectCity"
                    >
                    <template #option="item">
                      <div>{{ item.data.data.name }}</div>
                      <div>{{ item.data.data.region }}, {{ item.data.data.subRegion }}</div>
                    </template>
                      <a-input
                        placeholder="Select city"
                        class="custom"
                        style="height: 32px"
                      />
                    </a-auto-complete>
                  </a-row>   
                  <a-row>
                    <a-col :span="24">
                      <a-radio-group v-model:value="address.toWarehouse" size="small" style="width: 100%; margin-bottom: 15px;" button-style="solid" @change="changeToggle(i)">
                        <a-radio-button :value=false style="width: 50%; text-align: center;">Home</a-radio-button>
                        <a-radio-button :value=true style="width: 50%; text-align: center;">Pick-up point</a-radio-button>
                      </a-radio-group>
                    </a-col>
                  </a-row>
                  <a-auto-complete
                    v-if="!address.toWarehouse"
                    v-model:value="address.street"
                    :options="adr"
                    :disabled="!isValidCity(i)"
                    @search="suggestions(i)"
                    style="width: 100%"
                    @select="onSelectAddress"
                  >
                    <a-input
                      :placeholder="isValidCity(i)?   'Enter address':'First select city'"
                      class="custom"
                      style="height: 32px"
                    />
                  </a-auto-complete>
                  <a-auto-complete
                    v-if="address.toWarehouse"
                    v-model:value="address.pvzAddress"
                    :options="getPvz({i, pvzQuery})"
                    :disabled="!isValidCity(i)"
                    style="width: 100%"
                    @select="onSelectPvz"
                    @input='searchPvz($event.target.value)'
                  >
                    <a-input
                      :placeholder="isValidCity(i)?   'Select PVZ':'First select city'"
                      class="custom"
                      style="height: 32px"
                    />
                  </a-auto-complete>
                </a-card>
            </div>
          <a-row>
            <a-col style="padding-top: 10px;" :span="24">
              <a-button type="dashed" block @click="addAddress">
                <PlusSquareOutlined /> Add address
              </a-button>
            </a-col>
          </a-row>
          <a-row style="padding-top: 20px;">
            <a-col :span="24">
              <a-button type="primary" block html-type="submit">Add recipient</a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
    </a-col>
    <LoadingSpiner  v-if="loading"/>
  </a-row>
</template>

<script setup>
  import {CloseOutlined, PlusSquareOutlined} from '@ant-design/icons-vue';
</script>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { message } from "ant-design-vue";
import LoadingSpiner from '@/components/Loading-Spiner.vue';

export default {
  components: {  LoadingSpiner},

  data: () => ({
    loading: false,
    dateFormat: 'YYYY/MM/DD',
    isShowMap: false,
    defLocation: null,
    pvzQuery: '', 
    country: "RU",
    countries: [
      {
        value: 'RU',
        label: 'Russian Federation',
      },
    ],
  }),

  computed: {
    ...mapGetters(['addresses', 'adr', 'recipient', 'getPvz']),
  },

  methods: {
    ...mapMutations(['addAddress', 'setCityCode', 'removeAddress', 'resetPvz', 'resetStreet', 'setDateOfIssue', 'setDateOfBirth', 'setZipCode', 'setOfficeCode', 'resetForm']),
    ...mapActions(['suggestions', 'sentToServer', 'getCity', 'suggestionsPvz']),

    isValidCity (i) {
      return (this.addresses[i].cityAddress.cityCode && this.addresses[i].cityName && this.addresses[i].cityName.length > 2)? true: false
    },

    searchPvz(query){
      this.pvzQuery = query;
    },

    onSelectPvz(value, option){
      this.setOfficeCode(option)
    },
    onSelectCity(value, option){
      this.setCityCode(option)
      this.suggestionsPvz(option.data.code)
    },

    // меняю формат даты 
    updateDateOfBirth(date) {
      if (date!=null) {
        this.setDateOfBirth(date.toISOString().split('T')[0])
      } else {
        this.setDateOfBirth(null)
      }
    },

    changeToggle(i) {
      this.resetStreet(i)
      this.resetPvz(i)
    },

    // меняю формат даты 
    updateDateOfIssue (date) {
      if (date!=null) {
        this.setDateOfIssue(date.toISOString().split('T')[0])
      } else {
        this.setDateOfIssue(null)
      }
    },

    // действие по клику на подсказку в адресе
    onSelectAddress(value, option) {
      this.setZipCode(option)
    },

    // если нажата кнопка добавить но есть ошибки в заполнении
    onFinishFailed() {
      message.error("Error!");
    },

    // если нажата кнопка добавить
    async onFinish() {
      this.loading = true; 
      try {
        const success = await this.sentToServer();
        if (success) {
          message.success("Recipient added");
          this.resetForm()
          this.$router.push('/recipients');
        } else {
          throw new Error("Error sending to server");
        }
      } catch (error) {
        console.log(error);
        message.error("Error, Try Again Later!");
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.resetForm()
    // Если надо заполнить юзера для редактирования
    if (this.$route.params.id !== undefined) {
      // тут как бы надо бы доделать, но нет метода
      //this.$route.params.id
    }
  },
}
</script>
<style>
.ant-select-item-option-content {
  white-space: pre-line !important;
}
</style>
