import { message } from 'ant-design-vue';
import api from '@/services/api';
import suggestionsApi from '@/services/suggestionsApi.js';
export default {
  state() {
    return {
      recipient: {},
      addresses: [
        {
          cityAddress: {
            address: '',
          },
          toWarehouse: false,
          citySug: [],
          sugPvz: [],
        },
      ],
      adr: [],
    };
  },
  mutations: {
    resetForm(state) {
      state.recipient = {};
      state.adr = [];
      state.addresses = [
        {
          cityAddress: {
            address: '',
          },
          toWarehouse: false,
          citySug: [],
          sugPvz: [],
        },
      ];
    },
    setSugPvz(state, value) {
      state.addresses.forEach((address) => {
        if (address.cityAddress.cityCode === value.cityCode) {
          address.sugPvz = value.data.map((el) => ({
            data: el.code,
            value: el.code + ', ' + el.name,
          }));
        }
      });
    },
    setCitySug(state, value) {
      state.addresses[value.i].citySug = value.data.map((el) => ({
        data: { code: el.code, data: el },
        value:
          el.name +
          ', ' +
          el.region +
          (el.subRegion ? ', (' + el.subRegion + ')' : ''),
      }));
    },
    resetAddressLine(state, index) {
      state.addresses[index].cityAddress.address = '';
      state.addresses[index].cityAddress.cityCode = '';
      state.addresses[index].cityName = '';
      state.addresses[index].street = '';
      state.addresses[index].pvzAddress = '';
      delete state.addresses[index].cityAddress.zipCode;
    },
    resetStreet(state, index) {
      state.addresses[index].street = '';
      delete state.addresses[index].cityAddress.zipCode;
    },
    resetPvz(state, index) {
      state.addresses[index].officeCode = null;
      state.addresses[index].pvzAddress = '';
    },
    addAddress(state) {
      state.addresses.push({
        cityAddress: {
          address: '',
        },
        toWarehouse: false,
      });
    },
    setOfficeCode(state, option) {
      state.addresses.forEach((el) => {
        if (el.pvzAddress === option.value) {
          el.officeCode = option.data;
        }
      });
    },
    setCityCode(state, option) {
      state.addresses.forEach((el) => {
        if (el.cityName === option.value) {
          el.cityAddress.cityCode = option.data.code;
          el.officeCode = null;
        }
      });
    },
    removeAddress(state, index) {
      state.addresses.splice(index, 1);
    },
    setAdr(state, suggestions) {
      state.adr = suggestions;
    },
    setDateOfBirth(state, date) {
      state.recipient.dateOfBirth = date;
    },
    setDateOfIssue(state, date) {
      state.recipient.dateOfIssue = date;
    },
    setZipCode(state, option) {
      state.addresses.forEach((el) => {
        if (el.street === option.value) {
          el.cityAddress.zipCode = option.data.postal_code;
          el.officeCode = null;
        }
      });
    },
  },
  getters: {
    getPvz: (state) => (data) => {
      const query = data.pvzQuery;
      const scope = state.addresses[data.i].sugPvz;
      return scope.filter((p) =>
        p.value.toLowerCase().includes(query.toLowerCase())
      );
    },
    addresses(state) {
      return state.addresses;
    },
    adr(state) {
      return state.adr;
    },
    recipient(state) {
      return state.recipient;
    },
  },
  actions: {
    async suggestions({ state, commit }, i) {
      try {
        const parts = state.addresses[i].cityName.split(',');
        let data =
          parts[0] +
          ', ' +
          (parts[2] ? parts[2] : '') +
          ', ' +
          state.addresses[i].street;
        const response = await suggestionsApi.post(
          '/suggest/address',
          JSON.stringify({ query: data })
        );
        const suggestions = response.data.suggestions.map((e) => ({
          value: e.unrestricted_value,
          data: e.data,
        }));
        commit('setAdr', suggestions);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      }
    },
    async sentToServer({ state }) {
      const normalizationResult = await this.dispatch('normalizeRecipient');
      if (!normalizationResult) {
        console.log('Address normalization failed, aborting server request.');
        return false;
      }
      try {
        console.log(state.recipient)
        await api.post('/recipient-template', JSON.stringify(state.recipient));
        return true;
      } catch (error) {
        console.error('Error sending to server:', error);
        message.error(error.message);
        return false;
      }
    },
    async normalizeRecipient({ state, commit }) {
      for (let index = 0; index < state.addresses.length; index++) {
        const address = state.addresses[index];
        if (address.officeCode != null) {
          address.cityAddress.address = address.pvzAddress;
          state.recipient.addresses = state.addresses.map(
            ({ cityAddress, officeCode, toWarehouse }) => ({
              cityAddress,
              officeCode,
              toWarehouse,
            })
          );
        } else if (address.toWarehouse && address.officeCode === null) {
          message.error('Please select PVZ');
          commit('resetAddressLine', index);
          return false;
        } else if (
          !address.cityName ||
          !address.cityAddress.cityCode ||
          address.cityName === '' ||
          address.cityAddress.cityCode === ''
        ) {
          message.error('Please select city');
          commit('resetAddressLine', index);
          return false;
        } else if (!address.street || address.street.length < 3) {
          message.error('Please enter address');
          commit('resetStreet', index);
          return false;
        } else {
          let city = address.cityName.replace(/[,()]/g, '').toLowerCase();
          let street = address.street
            .replace(/[,()]/g, '')
            .toLowerCase()
            .split(' ');
          const containsCityWord = street.some((word) => city.includes(word));
          address.cityAddress.address = containsCityWord
            ? address.street
            : address.cityName + ', ' + address.street;
        }
      }
      state.recipient.addresses = state.addresses.map(
        ({ cityAddress, officeCode, toWarehouse }) => ({
          cityAddress,
          officeCode,
          toWarehouse,
        })
      );
      return true;
    },
    async suggestionsPvz({ commit }, cityCode) {
      const query = cityCode;
      if (query) {
        const response = await api.get(`/dict/office?cityCode=${query}`);
        response.cityCode = cityCode;
        commit('setSugPvz', response);
      }
    },
    async getCity({ state, commit }, i) {
      const query = state.addresses[i].cityName;
      if (query.length > 2) {
        const response = await api.get(
          `/dict/search/city?name=${query}&countryCode=RU`
        );
        response.i = i;
        commit('setCitySug', response);
      }
    },
  },
};
